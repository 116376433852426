
/*/deep/.el-button--default:hover{
    border-color: #FD4446 !important;
    color: #FD4446 !important;
    background: #ffffff;
}*/
.btn-red:focus, .btn-red:hover {
    color: #fff !important;
    border-color: #fd5658;
    background-color: #fd5658;
}
.promotion-content {
    margin-right: 20px;
    min-height: calc(100vh - 299px);
    .table-btn {
        padding-top: 10px;
    }
    /deep/ .el-table {
        min-height: calc(100vh - 447px);
        .el-table__body {
            tr td {
                padding: 27px 0;
            }
            .cell {
                .plan-name {
                    display: flex;
                    align-items: center;
                    /*justify-content: center;*/
                    cursor: pointer;
                    color: #1E63F1;
                    .edit-plan-iocn {
                        display: none;
                        font-size: 14px;
                        margin-left: 10px;
                    }
                    &:hover {
                        color: #1E63F1;
                        .edit-plan-iocn {
                            display: block;
                            color: #333;
                        }
                    }
                }
                .name-input {
                    display: block;
                    position: absolute;
                    top: 26px;
                    height: 18px;
                    width: 190px;
                }
                .daily-budget {
                    cursor: pointer;
                    color: #1E63F1;
                }
                .del-blue-btn {
                    cursor: pointer;
                    color: #1E63F1;
                }
            }
        }
    }
.table-top-btn {
    border: 1px solid #DCDFE6;
    color: #333;
}
}
/deep/ .el-notification {
    .el-notification__group {
        display: flex;
        align-items: center;
        .el-notification__content {
            margin-top: 0;
        }
    }
}
/deep/ .el-drawer {
    &.rtl {
        &:focus {
            outline: 0;
        }
    }
    .el-drawer__header {
        color: #333333;
        font-size: 24px;
        padding: 40px 20px 0 60px;
        margin-bottom: 0;
        font-weight: 500;
        span[role='heading'] {
            &:focus {
                outline: 0;
            }
        }
    }
}
.custom-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 60px;
    span {
        color: #989898;
        font-size: 14px;
        line-height: 1.5;
        margin-top: 10px;
    }
    .title {
        color: #333333;
        font-size: 24px;
        padding-bottom: 20px;
        margin-top: 0;
    }
    span:nth-child(4) {
        line-height: 27px;
    }
    .box-bottom {
        margin-top: 30px;
        font-size: 18px;
        color: #333333;
    }
    .CustomBudget-radio {
        margin-top: 30px;
        font-size: 18px;
        color: #333333;
    }
    /deep/ .el-radio__input.is-checked .el-radio__inner {
        border-color: #FD4446;
        background: #FD4446;
    }
    /deep/ .el-radio__inner:hover {
        border-color: #FD4446;
    }
    /deep/ .el-radio__input.is-checked+.el-radio__label {
        color: #333;
    }
    .isShowActive {
        background: #FFECC8!important;
        border-color: #ffffff!important;
    }
}
.hoverWrapper {
    padding: 10px 10px 10px 20px;
    z-index: 9999;
    position: fixed;
    top: 10px;
    left: 20px;
    width: 210px;
    /*height: 200px;*/
    background-color: #FFF;
    border: 1px solid #EEE;
    font-size: 18px;
    p{
        line-height: 27px;
    }
}
.clickWrapper{
    z-index: 9999;
    position: fixed;
    top: 10px;
    left: 20px;
    width: 246px;
    padding-top: 20px;
    background-color: #FFF;
    border: 1px solid #EEE;
    padding-left: 20px;
    font-size: 18px;
    padding-bottom: 30px;
    /deep/.el-radio{
        margin-top: 20px;
    }
    /deep/.el-radio-group{
        display: flex;
        flex-direction: column;
    }
    /deep/.el-radio__label{
        color: #333333!important;
        font-size: 18px;
    }
    .customInput{
        margin-top: 10px;
        display: flex;
        margin-left: 30px;
        padding-right: 34px;
        align-items: center;
        span{
            font-size: 18px;
        }
    }
    .clickWrapperBtn{
        margin-top: 20px;
        padding-left: 44px;
    }
}
.plan-table-cell {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    .plan-edit-btn {
        margin-right: 10px;
    }

}
